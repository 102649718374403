// Icons
import { ReactComponent as LinkedinLogo } from "../../icons/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../../icons/twitter.svg";
import { ReactComponent as TelegramLogo } from "../../icons/telegram.svg";
import { ReactComponent as InstagramLogo } from "../../icons/instagram.svg";
import Signature from "../../icons/signatureBlack.svg";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const notSignature = [
    `/projects/${location.pathname.split("/")[2]}`,
    `/blog/${location.pathname.split("/")[2]}`,
    "/contact",
  ];

  return (
    <>
      <footer className="pb-8 lg:pb-16   left-0 w-full  flex flex-col px-4  justify-between items-center absolute bottom-0">
        <div
          className={` pb-[50px] ${
            notSignature.includes(location.pathname)
              ? "hidden"
              : " sm:hidden block"
          } `}
        >
          <img src={Signature} alt="Razwan Alexander" className="" />
        </div>

        <div className="max-w-[1286px] w-full mx-auto flex gap-5 sm:flex-row flex-col  item-center sm:justify-between lg:px-[50px]">
          <h2 className="text-base sm:text-left text-center gotham_light text-[#575858] md:hidden">
            Copyright 2022
          </h2>
          <h2 className="text-base sm:text-left text-center gotham_light text-[#575858] hidden md:block">
            2022 Copyright to RazvanAlexander.com
          </h2>

          <div className="hidden sm:flex sm:justify-end justify-center items-center gap-3 lg:gap-5">
            <a
              href={"https://www.linkedin.com/in/razvanalexander/"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <LinkedinLogo className="hover:text-black text-[#cbcbcb] cursor-pointer" />
            </a>
            <a
              href={"https://twitter.com/razvanalexndr"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <TwitterLogo className="hover:text-black w-6 text-[#cbcbcb] cursor-pointer" />
            </a>
            <a
              href={"https://t.me/razvanalexander"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <TelegramLogo className="hover:text-black text-[#cbcbcb] cursor-pointer" />
            </a>
            <a
              href={"https://www.instagram.com/10secondstosunrise"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              <InstagramLogo className="hover:text-black text-[#cbcbcb] cursor-pointer" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
