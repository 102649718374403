export default {
  id: 2343212353,
  article_image: "digital-legacy-article.jpg",
  card_image: "digital-legacy-card.jpg",
  og_description: "Envision a place, a virtual place, where eternal life is possible, allowing you to pass on your personality, appearence, knowledge, ideas, and memmories to both your beloved ones, fostering continuous interaction with your being.",
  og_image: "digital-legacy-article.jpg",
  title: "Digital Legacy",
  slug: "digital-legacy",
  subtitle: "CURRENTLY DEVELOPING",
  gistText:
    "Envision a place, a virtual place, where eternal life is possible, allowing you to pass on your personality, appearence, knowledge, ideas, and memmories to both your beloved ones, fostering continuous interaction with your being.",
  content: `This article will be avalaible soon.


  `,
};
