/**
 * Formats the text to contain a specified number of words.
 *
 * @param {string} text - The input text to be formatted.
 * @param {number} maxWords - The maximum number of words.
 * @returns {string} The formatted text.
 */
export function shortenText(text, maxWords) {
  if (!text) return;
  const words = text.split(" ");

  if (words.length <= maxWords) {
    // Text is already within the word limit
    return text;
  } else {
    // Truncate text at the specified word limit
    const truncatedText = words.slice(0, maxWords).join(" ");
    return truncatedText + " ...";
  }
}
