export default {
  id: 2343212353,
  article_image: "our-brain-article.jpg",
  card_image: "our-brain-card.jpg",
  og_description: "What if we can create new senses for people? A different approach about how we see life.",
  og_image: "payouts_card_image_1.png",
  title: "Can we upload knowledge to our brain?",
  slug: "can-we-upload-knowledge-to-our-brain",
  subtitle: "IDEAS ON THE TABLE",
  gistText:
    "This text should be finished",
  content: `This article will be avalaible sooner that you expect.
  `,
};
