import React, { Suspense } from "react";

// Components
import Hero from "../components/landing/Hero";
// import InfluenceArticle from "../components/landing/InfluenceArticle";
import Interview from "../components/landing/Interview";
// import SliderSection from "../components/landing/SliderSection";

// Data
import Work from "../data/projects";
import Blog from "../data/blog.js";
import Idea from "../components/landing/Idea";
import { Helmet } from "react-helmet";

const AriaOfExpertise = React.lazy(() =>
  import("../components/landing/AriaOfExpertise")
);

const SliderSection = React.lazy(() =>
  import("../components/landing/SliderSection")
);
const InfluenceArticle = React.lazy(() =>
  import("../components/landing/InfluenceArticle")
);
const Index = () => {
  return (
    <>
      <Helmet>
        <meta name="title" content="Razvan Alexander - Personal Website" />
        <meta
          name="description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https:/razvanalexander.com" />
        <meta
          property="og:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="og:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="og:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Razvan Alexander - Personal Website"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https:/razvanalexander.com" />
        <meta
          property="twitter:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="twitter:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="twitter:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpgg"
        />
      </Helmet>
      <Hero />
      <Suspense fallback={<div>Loading...</div>}>
        <InfluenceArticle />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <SliderSection
          heading={"projects & ideas"}
          buttonPath=""
          sliderData={Work}
          seeAll={"see all projects"}
          path={"/projects"}
        />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <AriaOfExpertise />
      </Suspense>
      {/* 
      <Suspense fallback={<div>Loading...</div>}>
        <SliderSection
          heading={"vision of life"}
          buttonPath=""
          sliderData={Blog}
          seeAll={"read all articles"}
          path={"/blog"}
        />
      </Suspense> */}
      <Idea />
      <div className="max-w-[274px] w-full h-[0.5px] border-t border-t-[#DCDCDC] mx-auto sm:hidden block pb-40 mt-40" />
    </>
  );
};

export default Index;
