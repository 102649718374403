import React from "react";
import Helmet from "react-helmet";
import Heading from "../components/reusable-ui/Heading";
import { Link } from "react-router-dom";

const Story = () => {
  return (
    <>
      <Helmet>
        <title>My Story | Razvan Alexander</title>
        <meta name="title" content="Razvan Alexander - Personal Website" />
        <meta
          name="description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https:/razvanalexander.com" />
        <meta
          property="og:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="og:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="og:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Razvan Alexander - Personal Website"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https:/razvanalexander.com" />
        <meta
          property="twitter:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="twitter:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="twitter:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpgg"
        />
      </Helmet>
      <main>
        <div className="mt-[250px] flex items-center justify-center w-full gap-7  flex-col">
          <Heading className={"text-2xl leading-[30px] lg:lowercase uppercase"}>
            my story
          </Heading>
          <p className="text-deepgrey font-light text-sm leading-5 lg:hidden">
            WHO AM I?
          </p>
        </div>
        <section className="w-full px-5 flex items-center flex-col gap-5 md:gap-10 mt-[173px] md:mt-[250px] pb-[200px]">
          <div className="w-full flex flex-col items-center">
            <img
              className="lg:max-w-[1040px] object-cover object-center"
              src={`/images/hero_main.png`}
              alt="Article"
            />
            <div className="mt-[43px] md:mt-[87px] flex flex-col gap-6  w-fit mx-auto mb-[100px] sm:mb-0">
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                Entrepreneur - Investor - Chief Product Officer -
                Environmentalist
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                Presently, I hold the position of CEO at&nbsp;
                <a
                  href="https://razvanalexander.com/projects/the-verbyo-project"
                  target="_blank"
                >
                  <span>
                    <strong>VerbyoApp</strong>
                  </span>
                </a>
                , an innovative platform designed for organic social media
                marketing distribution.&nbsp;
                <a href="https://verbyo.com">
                  <span>
                    <strong>VerbyoApp</strong>
                  </span>
                </a>
                &nbsp;offers a powerful tool for advertisers, content creators,
                foundations, and local authorities, enabling them to amplify
                their brand messages or advocate for social causes by leveraging
                the voices of millions of genuine individuals across various
                social media networks
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                Over the years, I have had the privilege of living in various
                countries around the world, which has granted me invaluable
                opportunities to gain a profound understanding of diverse
                cultures, customs, and ways of life. This global exposure has
                nurtured my empathy and deep appreciation for the richness of
                human diversity.
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                My strong conviction lies in the belief that collective action
                can ignite transformative change within our communities, leading
                to a more prosperous and inclusive world for all. This belief
                serves as the driving force behind my dedication to developing
                and supporting products that ts not only drive positive changes
                within communities, but also empower large groups of
                individuals, facilitate greater access to education, and enhance
                the delivery of medical services. Ultimately, they contribute to
                making our world a better place, one step at a time.
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                One of the significant milestones in my journey was establishing
                the foundation for my inaugural non-profit endeavor.&nbsp;
                <strong>
                  <a
                    href="https://razvanalexander.com/projects/verbyo-digital-foundation"
                    target="_blank"
                  >
                    <span>The Verbyo Foundation</span>
                  </a>
                </strong>
                &nbsp;stands as a pioneering digital non-profit organization
                that harnesses the power of social media platforms to drive
                social initiatives and provide support to other foundations in
                their endeavors. By mobilizing social media users, the Verbyo
                Foundation is able to effectively execute these initiatives,
                creating a positive impact in various areas.&nbsp;
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                Throughout my journey, I have amassed extensive expertise across
                various domains, including product development, social media
                analytics, psychographics, business development, and network
                building. This invaluable experience serves as a cornerstone for
                me in creating products that truly bring value and benefit to
                individuals.
              </p>
              <p className="text-base md:text-[18px] max-w-[721px] text-[#575858] leading-6 ">
                {" "}
                Rather than focusing on cultivating a personal brand, I have
                made a deliberate choice to prioritize the development of
                superior products that cater to the needs and aspirations of
                people.
              </p>
            </div>
          </div>
          <Link to="/">
            <img
              src="./images/logo-black.png"
              alt="Alexander"
              className="max-w-[560px] mt-20 hidden sm:block"
            />
          </Link>
        </section>
      </main>
    </>
  );
};

export default Story;
