import React, { useState, useEffect } from "react";
import Heading from "../components/reusable-ui/Heading";

import SliderCard from "../components/landing/SliderCard";
import Signature from "../icons/signatureBlack.svg";

// Data
import WorkData from "../data/projects.js";
import BlogData from "../data/blog";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Article = () => {
  const [content, setContent] = useState(null);
  const [type, setType] = useState(null);
  const [suggestedArticles, setSuggestedArticle] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const exists1 = WorkData.find(
      (item) => item.slug == location.pathname.split("/")[2]
    );
    const exists2 = BlogData.find(
      (item) => item.slug == location.pathname.split("/")[2]
    );

    if (exists1) {
      setContent(exists1);
      setType("projects");
    } else if (exists2) {
      setContent(exists2);
      setType("blog");
    } else {
      setContent(null);
    }
  }, []);

  const getTwoRandomElements = (array) => {
    const randomIndex1 = Math.floor(Math.random() * array.length);
    const randomIndex2 = Math.floor(Math.random() * array.length);
    const randomElement1 = array[randomIndex1];
    const randomElement2 = array[randomIndex2];
    return [randomElement1, randomElement2];
  };

  useEffect(() => {
    if (type) {
      if (type === "projects") {
        setSuggestedArticle(getTwoRandomElements(WorkData));
      } else if (type === "blog") {
        setSuggestedArticle(getTwoRandomElements(BlogData));
      }
    }
  }, [type]);

  return (
    <main>
      <Helmet>
        <title>{content?.title ?? " | Razvan Alexander"}</title>
        <meta name="description" content={content?.og_description ?? ""} />
        <meta name="og:description" content={content?.og_description ?? ""} />
        <meta property="og:title" content={content?.title ?? ""} />
        <meta
          property="og:image"
          content={"/images/" + content?.og_image ?? ""}
        />
        <meta property="og:image:alt" content={content?.title ?? ""} />
      </Helmet>
      <div className="mt-[250px] flex items-center justify-center w-full gap-7  flex-col">
        <Heading className={"text-[24px] text-center leading-[30px]"}>
          {content?.title}
        </Heading>
        <p className="font-light text-deepgrey text-sm leading-5">
          {content?.subtitle}
        </p>
      </div>
      <section className="w-full px-5 lg:px-0 flex items-center flex-col gap-4 md:gap-10 mt-[175px] md:mt-[250px] pb-[100px]">
        {content ? (
          <div className="w-full flex flex-col items-center">
            <div
              className="max-w-[1040px]  w-full "
              style={{
                backgroundImage: `url(/images/${content.article_image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                aspectRatio: "130 / 67",
              }}
            ></div>
            {/* <img
							className="object-center object-cover lg:max-w-[1040px] "
							src={`/images/${content.article_image}.png`}
							alt="Article"
						/> */}
            <div className="mt-10 md:mt-[87px] flex flex-col gap-6  w-fit mx-auto">
              <p
                dangerouslySetInnerHTML={{ __html: content.content }}
                className="text-base md:text-[18px] md:max-w-[721px] text-[#575858] leading-6 "
              ></p>
              <div className="mt-10">
                <h2 className="text-black text-base md:text-[26px] leading-6 gotham_bold">
                  {content.title2}
                </h2>
                <p
                  dangerouslySetInnerHTML={{ __html: content.content2 }}
                  className="text-[18px] mt-6 max-w-[721px] text-[#575858] leading-6 "
                ></p>
              </div>
            </div>
          </div>
        ) : (
          <p className="gotham_light text-red-500 text-xl leading-5">
            No article against this id.
          </p>
        )}
      </section>
      <div className="max-w-[274px] md:max-w-2xl w-full h-[0.5px] border-t border-t-[#DCDCDC] mx-auto mt-[36px]  block" />

      <div className={` py-12  block sm:scale-125 mb-16`}>
        <Link to="/">
          <img src={Signature} alt="Razwan Alexander" className="mx-auto" />
        </Link>
      </div>

      <div className="relative ">
        <div className="hidden md:flex bg-[#F4F7FD]  px-5 lg:px-0 md:gap-5 gap-10  md:flex-row flex-col items-center justify-center left-0 w-full  mb-24">
          {suggestedArticles ? (
            suggestedArticles.map((item, index) => (
              <SliderCard
                breakpoint={"xl"}
                key={index}
                path={type ? `/${type}` : `/${type}`}
                id={item.slug}
                imageName={item.card_image}
                title={item.title}
                gistText={item.gistText}
                subtitle={item.subtitle}
              />
            ))
          ) : (
            <p className="gotham_light text-red-500 text-xl leading-5">
              No articles to show.
            </p>
          )}
        </div>
      </div>
      <div className="md:hidden bg-[#F4F7FD] px-5 mt-[70px] mb-28">
        {suggestedArticles ? (
          suggestedArticles
            .slice(0, 1)
            .map((item, index) => (
              <SliderCard
                breakpoint={"xl"}
                key={index}
                path={type ? `/${type}` : `/${type}`}
                id={item.slug}
                imageName={item.card_image}
                title={item.title}
                gistText={item.content[1]}
              />
            ))
        ) : (
          <p className="gotham_light text-red-500 text-xl leading-5">
            No articles to show.
          </p>
        )}
      </div>
    </main>
  );
};

export default Article;
