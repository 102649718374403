import React from "react";
import { useNavigate } from "react-router-dom";
import { shortenText } from "../../utils/text";

const ArticleCard = ({ heading, gistText, image, id, path, subtitle }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(path);
      }}
      className="max-w-[1100px] p-2 md:p-0 lg:rounded-lg flex md:flex-row flex-col-reverse md:gap-5 md:items-center md:grid md:grid-cols-[4fr_3fr] justify-between w-full bg-white hover:cursor-pointer hover:shadow-xl"
    >
      <div className=" md:pl-8  lg:pl-[55px] px-5 md:px-0 py-5 md:py-6  pb-12 md:pb-0 lg:pt-14">
        <div>
          <h2
            className={`font-bold text-left sm:text-center md:text-left relative mb-2 w-full  text-xl leading-9 xl:text-[26px] xl:max-w-[20ch]`}
          >
            {shortenText(heading, 10)}
          </h2>

          <p className="text-[#5a5a5a]  mb-4">{subtitle}</p>
        </div>
        <p className="leading-6 mt-3 md:mt-7 !text-[16px] gotham_light text-[#5a5a5a] max-w-[475px]">
          {gistText}
        </p>
        <button
          onClick={() => {
            navigate(path);
          }}
          className="md:block hidden my-16 mb-12 lg:mb-20 gotham_book_italic hover:underline text-xs text-[#5A5A5A] lg:text-base"
        >
          read more
        </button>
      </div>
      <div className="cursor-pointer p-3 w-full h-48 md:h-full">
        <img
          src={`./images/${image}`}
          className={
            "aspect-ratio  w-full h-full md:max-w-none object-cover object-top rounded-md"
          }
          alt="What is verbyo?"
        />
      </div>
    </div>
  );
};

export default ArticleCard;
