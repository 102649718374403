import React from "react";

const Heading = ({ children, size, color, className }) => {
	return (
		<>
			<h1
				style={{
					color: color ? color : "black",
				}}
				className={` lg:leading-[46px] lg:text-[${
					size ? size : "36px"
				}] gotham_light ${className} `}
			>
				{children}
			</h1>
			<span className="hidden lg:text-[36px]"></span>
		</>
	);
};

export default Heading;
