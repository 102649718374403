export default {
  id: 2343212353,
  article_image: "verbyo-foundation-article.jpg",
  card_image: "verbyo-foundation-card.jpg",
  og_description: "The first digital non profit organization that harnesses the power of social media platforms to drive social initiatives.",
  og_image: "verbyo-foundation-article.jpg",
  title: "Verbyo Digital Foundation",
  slug: "verbyo-digital-foundation",
  subtitle: "FOUNDER | 2023",
  gistText:
    "Established in 2023, is the first digital non-profit organization that harnesses the potential of social media platforms to drive social initiatives.",
  content: `<p><strong>Verbyo Foundation</strong>, established in 2023, is a pioneering digital non-profit organization that harnesses the potential of social media platforms to drive social initiatives. It also collaborates with other foundations, relying on the participation of social media users to carry out these initiatives.</p>

  <p>&nbsp;</p>
  
  <p>I firmly believe that through collective efforts, we can overcome significant challenges, initiate permanent changes within our communities, and serve as a source of inspiration for generations to come.</p>
  
  <p>&nbsp;</p>
  
  <p>The foundation&#39;s primary focus lies in raising awareness and implementing projects in the domains of&nbsp;<strong>education, renewable energy, and wildlife preservation</strong>. Our unwavering commitment is to create a sustainable and equitable world. We firmly hold the conviction that digital platforms possess immense potential as powerful tools to achieve this noble objective.</p>
  
  <p>&nbsp;</p>
  
  <p>The inspiration for this project originated in 2022 when I was living in Jakarta and had the privilege of meeting the remarkable people of&nbsp;<a href="https://www.iscofoundation.or.id/" target="_blank" title="ISCO Foundation">ISCO (Indonesia Street Children Organisation) Foundation.</a></p>
  
  <p>&nbsp;</p>
  
  <p>Witnessing their remarkable 25-year journey from Zero to Hero project, left a profound impact. ISCO&#39;s mission is to provide educational support, learning and activity centers, health and nutrition, and protection to vulnerable children in Indonesia, fostering their growth and development.</p>
  
  <p>&nbsp;</p>
  
  <p>A year later, in 2023, Verbyo Foundation came into existence. Currently, it remains a modest endeavor, an initiative that I hope will inspire others to join us in providing support. Together, we can create meaningful change.</p>
  
  <p>&nbsp;</p>
  
  <p>Verbyo Foundation primarily receives funding and support from Verbyo App, the world&#39;s first organic social media distribution platform.</p>
  
  <p>&nbsp;</p>
  
  <p>To learn more about Verbyo Foundation and share your ideas,&nbsp;<a href="https://verbyofoundation.org/" target="_blank" title="Verbyo Foundation">kindly explore our website</a>.</p>
  
  `,
};
