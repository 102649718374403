import { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "./components/Layout";

// Routes
import Home from "./routes";
import Contact from "./routes/Contact";
import Work from "./routes/Work";
import Blog from "./routes/Blog";
import Article from "./routes/Article";
import Story from "./routes/Story";
import Loading from "./components/reusable-ui/Loading";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // scroll to top of page on every route change
  }, [location]);

  return (
    <>
      {loading && <Loading />}
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="projects" element={<Work />} />
          <Route path="blog" element={<Blog />} />
          <Route path="/blog/:id" element={<Article />} />
          <Route path="/projects/:id" element={<Article />} />
          <Route path="/my-story" element={<Story />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
