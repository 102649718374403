import React from "react";

const Loading = () => {
	return (
		<main className="w-screen bg-gray-50 top-0 left-0 fixed z-50 h-screen flex items-center justify-center">
			<div>
				<img
					src="/images/logo-black-new.png"
					width={500}
					alt="Loader"
					className="animate-bounce"
				/>
			</div>
		</main>
	);
};

export default Loading;
