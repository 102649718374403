import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { shortenText } from "../../utils/text";

const SliderCard = ({
  title,
  gistText,
  imageName,
  id,
  breakpoint,
  path,
  subtitle,
}) => {
  const navigate = useNavigate();
  const breakpointClass = breakpoint ? breakpoint : "lg";
  return (
    <>
      <div
        onClick={() => {
          navigate(`${path}/${id}`);
        }}
        className={`mx-auto w-full sm:max-w-[500px] lg:max-w-none lg:w-[700px] lg:rounded-md  cursor-pointer mb-4 p-3 bg-white relative flex lg:flex-row flex-col-reverse lg:justify-between items-center lg:grid lg:grid-cols-2`}
      >
        <Link style={{ display: "none" }} to={`${path}/${id}`} />
        <div
          className={`flex flex-col items-stretch lg:gap-4 mt-[22px] lg:mt-0 h-full px-4  pb-16 lg:pt-8 lg:pb-8`}
        >
          <h2
            className={`font-bold text-left relative  w-full lg:max-w-[25ch] text-xl leading-9 lg:text-[26px]`}
          >
            {shortenText(title, 10)}
          </h2>

          <div className="hidden  items-center uppercase font-montserrat font-light text-deepgrey mt-2 gap-4 lg:block">
            <p className="border-l max-w-[150px] border-deepgrey lg:border-none">
              {subtitle}
            </p>
          </div>

          <p className="lg:hidden uppercase font-montserrat font-light text-deepgrey text-sm mt-2 mb-4">
            {subtitle}
          </p>

          <p
            className={`sm:max-w-[500px] text-left lg:text-left lg:max-w-[255px]  lg:overflow-hidden  gotham_light leading-[22px] text-base text-[#575858] lg:pt-8`}
          >
            {shortenText(gistText, 38)}
          </p>
        </div>
        <div className=" w-full overflow-hidden  h-48 lg:min-h-[25rem] flex-grow">
          <img
            src={`/images/${imageName}`}
            alt={title}
            className="w-full h-full  object-cover object-top md:object-center rounded-md"
          />
        </div>
      </div>
    </>
  );
};

export default SliderCard;
