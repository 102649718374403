import React from "react";
import { Link, useLocation } from "react-router-dom";
import Signature from "../../icons/signatureBlack.svg";
import { ReactComponent as InstagramLogo } from "../../icons/signatureBlack.svg";

const navigationList = [
  { name: "home", path: "/" },
  { name: "my story", path: "/my-story" },
  { name: "work", path: "/projects" },
  // { name: "blog", path: "/blog" },
  { name: "contact", path: "/contact" },
];

const Navigation = () => {
  const [toggle, setToggle] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);

  const location = useLocation();
  const isHome = location.pathname === "/";
  console.log(location);
  const isActive = (path) => "/" + location.pathname.split("/")[1] === path;

  const handleScroll = () => {
    console.log(window.scrollY);
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`${
        isScrolled
          ? "fixed sm:fixed md:fixed xl:fixed top-0 left-0 right-0 w-screen bg-white sm:bg-white py-5 h-fit z-[9999]"
          : isHome
          ? ""
          : "py-5"
      }`}
    >
      <nav
        className={`${
          toggle
            ? " w-screen h-screen py-2 top-0 sm:relative bg-white "
            : "w-full h-4 bg-transparent   "
        } ${!isScrolled && isHome ? "opacity-0" : ""}
        ${isScrolled ? "!opacity-100" : "!opacity-100"}
       sm:w-full  flex-col  lg:flex-row justify-between max-w-[1360px] mx-auto sm:h-auto sm:bg-transparent w-full flex items-center transition-all  z-10`}
      >
        <div className="flex items-center">
          <Link to="/">
            <img
              alt="signature"
              src={Signature}
              className="hidden lg:block w-56 h-auto "
            />
          </Link>
          <p className="hidden lg:block text-deepgrey pl-4 border-l border-l-deepgrey flex-grow">
            {formatPathname(location.pathname)}
          </p>
        </div>
        <button
          aria-expanded={toggle}
          className={`menu sm:hidden max-h-[40px] flex ${
            toggle ? "opened" : ""
          } overflow-hidden mx-auto justify-center absolute z-10 top-0 inset-0`}
          onClick={(e) => {
            e.stopPropagation();
            setToggle(!toggle);
          }}
          aria-label="Main Menu"
        >
          <svg
            width={100}
            className={`w-[35px] md:w-[100px]  ${toggle ? "opened" : ""}`}
            height={60}
            viewBox="0 0 100 100"
          >
            <path
              className={`${
                isActive("") ? (toggle ? "line" : "line-home") : "line"
              } line1`}
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path
              className={`${
                isActive("") ? (toggle ? "line" : "line-home") : "line"
              } line2`}
              d="M 20,50 H 80"
            />
            <path
              className={`${
                isActive("") ? (toggle ? "line" : "line-home") : "line"
              } line3`}
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>

        <ul
          className={`${
            toggle ? "" : "translate-y-[-200%] sm:translate-y-0 relative z-0"
          } sm:max-w-[340px] transition-all ease-in-out duration-300    sm:h-auto justify-center   mx-auto  lg:mx-0 w-full  flex sm:flex-row  flex-col items-center gap-[34px] sm:gap-5`}
        >
          {navigationList?.map((item, index) => {
            return (
              <Link
                onClick={() => {
                  setToggle(false);
                }}
                key={`${index + " " + item.name}`}
                to={item.path}
              >
                <li
                  className={`${
                    isActive(item.path) ? "text-black" : "text-[#707070]"
                  }
							
								
								leading-7 text-2xl sm:text-base sm:leading-[46px] font-light gotham_light gotham_light_hover hover:text-black `}
                >
                  {item.name}
                </li>
              </Link>
            );
          })}
        </ul>
        <div
          className={`${
            toggle ? "" : "translate-y-[-400%] sm:translate-y-0 relative z-0"
          } transition-all ease-in-out duration-300  sm:hidden flex flex-col`}
        >
          <div className="w-full max-w-[275px] bg-[#DCDCDC] h-[0.8px] mx-auto" />
          <InstagramLogo className="mt-[52px] mx-auto" />
        </div>
      </nav>
    </header>
  );
};

export default Navigation;

function formatString(inputString) {
  // Capitalize the entire word
  const words = inputString.split("-");
  const formattedString = words.map((word) => word.toUpperCase()).join(" ");

  return formattedString;
}

function formatPathname(pathname) {
  if (pathname === "/") return "Razvan Alexander";
  const pathParts = pathname.split("/");
  const firstPart = formatString(pathParts[1]);
  const lastPart = pathParts[pathParts.length - 1];
  const formattedPath = formatString(lastPart);

  if (pathParts.length === 2) return firstPart;
  return firstPart + " / " + formattedPath;
}
// https://verbyo-six.vercel.app/
