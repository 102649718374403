export default {
  id: 2343212353,
  article_image: "Nuucleum.jpg",
  card_image: "nuucleum-card.jpg",
  og_description: "Store your DNA sequence securely in blockchain for later use. ",
  og_image: "Nuucleum.jpg",
  title: "Nuucleum - DNA Blockchain Storage",
  slug: "nuucleum-project",
  subtitle: "PROJECT ADVISOR | 2020",
  gistText:
    "A blockchain-based project provides the opportunity to store your DNA information on your mobile phone, like storing photos in a cloud service. This enables easy and secure access to yor DNA data whenever you need it.",
  content: `<p>Nuucleum is a blockchain-based project about giving you the possibility of storing your DNA like you store your photos in your cloud service.&nbsp;Having your DNA sequenced and stored for your personal use is one of the most important things you can have.&nbsp;</p>

  <p>&nbsp;</p>
  
  <h2><strong>Why is this important for you?</strong></h2>
  
  <p>&nbsp;</p>
  
  <p>First of all, let me explain to you quickly what is this DNA. Deoxyribonucleic acid (as it is called scientifically) serves as the genetic blueprint of living organisms. It carries the hereditary information that determines the development, structure, and functioning of all known living organisms. This DNA is you. Everything that you are. Your genetic code contains information on how to build and maintain you as a living organism. You inherit this from your parents allowing for the transmission of genetic traits and characteristics across generations.&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>How can you use your sequenced DNA information?</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Here are 3 ways that your DNA can be useful to you:</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>The Witness:</strong>&nbsp;Probably one of the most important reasons that you should sequence and store your DNA. When we are healthy our genome has a particular sequence, but when we get sick some of the genes from our genome suffer modifications. Having the original proof helps doctors and researchers find out what genes have been modified.&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Personalized Medicine:</strong>&nbsp;DNA sequencing can provide insights into an individual&#39;s genetic predispositions and risk factors for certain diseases. By analyzing specific genetic markers, doctors can offer personalized medical advice, such as tailored prevention.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Pharmacogenomics:</strong>&nbsp;DNA sequencing can reveal genetic variations that affect how an individual metabolizes and responds to medications. This field, known as pharmacogenomics, can help healthcare professionals determine the most suitable medications and dosages for patients, minimizing adverse drug reactions and optimizing treatment outcomes.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <h2><strong>Smart Fact</strong></h2>
  
  <p>&nbsp;</p>
  
  <p><em>Now, here is an interesting thing about my DNA and your DNA. They are 99.9% identical. That 0.1% is what makes us different one from another. This 0.1% is what contributes to the diversity seen in human traits, such as our physical appearance, susceptibility to diseases, and other genetic characteristics. Most of these genetic differences are single-nucleotide polymorphisms (SNPs), which are variations in a single nucleotide base within the DNA sequence.</em></p>
  
  <p>&nbsp;</p>
  
  <h2>&nbsp;</h2>
  
  <h2><strong>How are we going to store your DNA?</strong></h2>
  
  <p>&nbsp;</p>
  
  <p>Nuucleum&#39;s decentralized storage system will be based on Fibonacci numbers having an infinite storage location, in other words, one entire genome will be stored in the entire network using the blockchain</p>
  
  <p>system until the last computer is available.</p>
  
  <p>&nbsp;</p>
  
  <p>Our DNA blueprint consists from letters A&#39;s, G&#39;s, C&#39;s, and T&#39;s, just 3 billion pairs of these letters. Now, in a perfect version of your DNA, all you would need is just a string of A, G, C, and T to make up one strand and if you would like to save it on a hard drive it will only take 700MB.&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>But, this is the blueprint in a perfect world, without taking in consideration all the mutations, but in reality, your DNA size will be around 200GB.&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Is it going to be safe?</strong></p>
  
  <p>&nbsp;</p>
  
  <p>Ensuring the highest level of security for your data is our topmost priority. By employing blockchain technology, we store and encrypt your data, guaranteeing an exceptionally high level of security.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Our project is currently in the development stage, and if you are i<a href="https://razvanalexander.com/contact">nterested in joining, providing funding, or sharing interesting ideas, please feel free to reach out to me.</a></p>
  
  `,
};
