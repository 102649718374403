import React from "react";
import Heading from "../components/reusable-ui/Heading";

import ArticleCard from "../components/reusable-ui/ArticleCard";

// Data
import BlogData from "../data/blog.js";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <main>
      <Helmet>
        <meta name="title" content="Razvan Alexander - Personal Website" />
        <meta
          name="description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https:/razvanalexander.com" />
        <meta
          property="og:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="og:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="og:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Razvan Alexander - Personal Website"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https:/razvanalexander.com" />
        <meta
          property="twitter:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="twitter:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="twitter:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpgg"
        />
      </Helmet>
      <div className="mt-[250px]   flex items-center justify-center w-full">
        <Heading color={"#707070"} className={"text-2xl"}>
          PROJECTS & IDEAS
        </Heading>
      </div>

      <section className="w-full px-5 lg:px-0 flex items-center flex-col gap-10 mt-[200px] lg:mt-[250px] pb-[200px] lg:pb-[400px] lg:max-w-6xl lg:mx-auto">
        {BlogData.map((item, index) => {
          return (
            <ArticleCard
              path={`/blog/${item.slug}`}
              id={item.slug}
              key={index + item.id}
              heading={item.title}
              gistText={item.gistText}
              image={item.card_image}
              subtitle={item.subtitle}
            />
          );
        })}
        <div className="max-w-[274px] w-full h-[0.5px] bg-[#DCDCDC] mx-auto mt-[78px] sm:hidden block" />
      </section>
    </main>
  );
};

export default Blog;
