import React, { Fragment, useState } from "react";

import PulseMapLogo from "../../icons/pulse-map-logo.png";
import VerbyoFoundation from "../../icons/verbyo-foundation.png";
import KLogo from "../../icons/k-logo.png";
import Nuucleum from "../../icons/nuucleum.png";
import Verbyo from "../../icons/verbyo.png";
import DigitalLegacy from "../../icons/digital-legacy.png";

const content = {
  "digitallegacy": (
    <p>
      Envision a place, a virtual place, where eternal life is possible, allowing you to pass on your personality, appearance, knowledge, ideas, and memories to both your beloved ones, fostering continuous interaction with your being.
      <br /> <br />
      This project is currently under development
    </p>
  ),
  "verbyo": (
    <p>
      Verbyo is the world's first platform for organic distribution in social media marketing, providing a unique opportunity for advertisers, foundations, content creators, and local authorities to effectively distribute their messages across various social media platforms through authentic individuals.
      <br /> <br />
      By incentivizing people to create and share posts on their personal social media accounts, Verbyo revolutionizes social media advertising, combats misinformation, and drives positive changes in communities.
    </p>
  ),
  "verbyofoundation": (
    <p>
      Verbyo Foundation, established in 2023, is a pioneering digital non-profit organization that harnesses the potential of social media platforms to drive social initiatives. It also collaborates with other foundations, relying on the participation of social media users to carry out these initiatives.
      <br /> <br />
      The foundation's primary focus lies in raising awareness and implementing projects in the domains of education, renewable energy, and wildlife preservation. Our unwavering commitment is to create a sustainable and equitable world. We firmly hold the conviction that digital platforms possess immense potential as powerful tools to achieve this noble objective
    </p>
  ),
  "k": (
    <p>
      An app tailored specifically for globetrotters, influencers and content creators, that allows them to monetize their travel experiences in a smart and createive way.

      <br /> <br />
    </p>
  ),
  "pulse-map": (
    <p>
      PulseMap is an innovative app designed specifically for cardiologists. This crowd-sourced mobile application is set to revolutionize the way cardiologists communicate, collaborate, and seek expert opinions from their peers. 
      <br /> <br />
      It connects cardiologists to a vast network of experts who can provide valuable insights, opinions, and recommendations.
    </p>
  ),
  "nuucleum": (
    <p>
      Nuucleum is a blockchain-based project about giving you the possibility of storing your DNA like you store your photos in your cloud service. Having your DNA sequenced and stored for your personal use is one of the most important things you can have.
      <br /> <br />
    </p>
  ),
};

const ideasContent = [
  {
    id: "digitallegacy",
    image: DigitalLegacy,
  },
  {
    id: "verbyo",
    image: Verbyo,
  },
  {
    id: "verbyofoundation",
    image: VerbyoFoundation,
  },
  {
    id: "k",
    image: KLogo,
  },
  {
    id: "pulse-map",
    image: PulseMapLogo,
  },
  
  {
    id: "nuucleum",
    image: Nuucleum,
  },
  
  
];

export default function Idea() {
  const [hoveredContent, setHoveredContent] = useState("verbyo");

  return (
    <>
      <div className="hidden lg:block lg:mb-40">
        <h3 className="w-full lowercase whitespace-nowrap my-36 mt-48 lg:my-24   text-2xl text-center lg:text-start  lg:text-4xl leading-[46px] font-light  text-black">
          when ideas become real
        </h3>
        <div
          className="flex flex-col lg:grid justify-center items-end lg:items-center gap-8  lg:-mt-20 "
          style={{
            gridTemplateColumns: `repeat(${ideasContent.length}, 1fr)`,
          }}
        >
          {ideasContent.map((idea, idx) => (
            <img
              key={idx}
              src={idea.image}
              alt={idea.id}
              className="mx-auto block hover:cursor-pointer justify-self-center"
              onMouseOver={() => setHoveredContent(idea.id)}
            />
          ))}
        </div>
        {hoveredContent !== "" && (
          <Fragment>
            <div
              className="hidden lg:grid text-gray-500"
              style={{
                gridTemplateColumns: `repeat(${ideasContent.length}, 1fr)`,
              }}
            >
              {ideasContent.map((idea, idx) => (
                <div
                  className={`h-32 w-[1px] bg-gray-400 block  justify-self-center
                ${
                  idx === ideasContent.length - 1
                    ? "ml-12"
                    : idx !== 0
                    ? "mr-12"
                    : "ml-0"
                }
                ${idea.id === hoveredContent ? "" : "opacity-0"}
                `}
                ></div>
              ))}
            </div>
            <div className="hidden lg:block text-lightgrey">
              {content[hoveredContent]}
            </div>
          </Fragment>
        )}
      </div>
      <div className=" lg:hidden">
        <h3 className="w-full lowercase whitespace-nowrap my-36 mt-48 lg:my-24   text-2xl text-center lg:text-start lg:text-4xl leading-[46px] font-light  text-black">
          when ideas become real
        </h3>
        <div className="flex flex-col lg:grid justify-center items-end lg:items-center gap-8  lg:-mt-20 ">
          <img
            src={DigitalLegacy}
            alt=""
            className="mx-auto block hover:cursor-pointer justify-self-center"
          />{" "}
          <img
            src={Verbyo}
            alt=""
            className="mx-auto block hover:cursor-pointer justify-self-center"
          />
          <img
            src={VerbyoFoundation}
            alt=""
            className="mx-auto block hover:cursor-pointer justify-self-center"
          />
        </div>
      </div>
    </>
  );
}
