import { ReactComponent as LinkedinLogo } from "../icons/linkedin.svg";
import { ReactComponent as TwitterLogo } from "../icons/twitter.svg";
import { ReactComponent as TelegramLogo } from "../icons/telegram.svg";
import { ReactComponent as InstagramLogo } from "../icons/instagram.svg";

import React from "react";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta name="title" content="Razvan Alexander - Personal Website" />
        <meta
          name="description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https:/razvanalexander.com" />
        <meta
          property="og:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="og:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="og:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpg"
        />
        <meta
          property="og:image:alt"
          content="Razvan Alexander - Personal Website"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https:/razvanalexander.com" />
        <meta
          property="twitter:title"
          content="Razvan Alexander - Personal Website"
        />
        <meta
          property="twitter:description"
          content="Get to know how my work is driving positive changes within communities and contribute to making our world a better place. "
        />
        <meta
          property="twitter:image"
          content="https://razvanalexander.com/images/razvan-alexander-og-image.jpgg"
        />
      </Helmet>
      <div
        className="flex flex-col justify-center gap-32 px-5 lg:px-0 items-center"
        style={{
          height: "calc(100vh - 160px)",
        }}
      >
        <h2 className="text-center lg:hidden text-2xl md:text-[42px] text-deepgrey gotham_light break-words">
          iam
          <br /> @razvanalexander.com
        </h2>
        <h2 className="hidden lg:block text-2xl md:text-[42px] text-black gotham_light break-words">
          iam@razvanalexander.com
        </h2>

        <div className="flex  justify-center items-center gap-12">
          <a
            href={"https://www.linkedin.com/in/razvanalexander/"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            <LinkedinLogo className="hover:text-black text-[#cbcbcb] cursor-pointer w-10 h-10" />
          </a>
          <a
            href={"https://twitter.com/razvanalexndr"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            <TwitterLogo className="hover:text-black text-[#cbcbcb] cursor-pointer w-10 h-10" />
          </a>
          <a
            href={"https://t.me/razvanalexander"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            <TelegramLogo className="hover:text-black text-[#cbcbcb] cursor-pointer w-10 h-10" />
          </a>
          <a
            href={"https://instagram.com/10secondstosunrise"}
            target={"_blank"}
            rel="noopener noreferrer"
          >
            <InstagramLogo className="hover:text-black text-[#cbcbcb] cursor-pointer w-10 h-10" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;
