export default {
  id: 2343212353,
  article_image: "koviana-article.jpg",
  card_image: "koviana-project.jpg",
  og_description: "Travel and get paid. A social traveling app that helps people to monetize their journeys around the world.",
  og_image: "koviana-article.jpg",
  title: "Koviana - Interactive Traveling",
  slug: "koviana",
  subtitle: "CEO - FOUNDER | 2018 - 2020",
  gistText:
    "An app tailored specifically for globetrotters, influecers and content creators, that allows them to monetize their travel experiences in a smart and creative way.",
  content: `This article will be avalaible sooner that you expect.


  
  `,
};
