import React from "react";
import Signature from "../../icons/signature.svg";

const Hero = () => {
  return (
    <>
      <div className="xl:absolute  px-[35px] lg:px-0 hero_image w-full flex items-end md:items-center  h-screen top-0 left-0 right-0">
        <div className="max-w-[1286px] w-full mx-auto py-24 xs:py-4 md:py-20">
          <p className="gotham_light_italic sm:text-left text-center text-white text-xl  sm:text-2xl leading-[30px] max-w-[560px] mx-auto lg:ml-[110px] ">
            In order to create and deliver the perfect product you need to
            understand human beings behavior, their emotional needs, their
            culture and habits. Ultimately, all these form a pattern.
          </p>
          <img
            alt="signature"
            src={Signature}
            className="scale-150 lg:ml-72 object-contain mt-12"
          />
        </div>
      </div>
      <div className="xl:h-screen"></div>
    </>
  );
};

export default Hero;
