export default {
  id: 2343212353,
  article_image: "verbyo-app-article.jpg",
  card_image: "verbyo-app-card.jpg",
  og_description: "Post and earn. The first app in the world that rewards peopl everytime they post on their social media accounts.",
  og_image: "verbyp-app-article.jpg",
  title: "The Verbyo App",
  slug: "the-verbyo-app",
  subtitle: "CEO - FOUNDER | 2019 - Present",
  gistText:
    "The first app in the world that rewards people everytime they post on their social media accounts.",
  content: `<p><a href="https://verbyo.com/" target="_blank" title="Verbyo App "><span style="color:#2980b9"><strong>Verbyo</strong></span></a>&nbsp;is the world&#39;s first platform for organic distribution in social media marketing, providing a unique opportunity for advertisers, foundations, content creators, and local authorities to effectively distribute their messages across various social media platforms through authentic individuals.</p>

  <p>&nbsp;</p>
  
  <p>By incentivizing people to create and share posts on their personal social media accounts, Verbyo revolutionizes social media advertising, combats misinformation, and drives positive changes in communities.</p>
  
  <p>&nbsp;</p>
  
  <p>With over 200,000 active users already engaged, Verbyo has established its presence in six countries and aims to expand to 16 countries by the end of 2024. This strategic growth allows Verbyo to make a broader impact and empower more individuals to be part of the positive change in the digital advertising landscape.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Verbyo consists of two essential components:</p>
  
  <p>&nbsp;</p>
  
  <p><a href="https://verbyo.com/" target="_blank" title="Verbyo App Download"><span style="color:#2980b9"><strong>VerbyoApp</strong></span></a>, which enables individuals to monetize their social media presence by creating engaging content for their friends,</p>
  
  <p>&nbsp;</p>
  
  <p>and&nbsp;<a href="https://business.verbyo.com/" target="_blank" title="Verbyo Business"><span style="color:#2980b9"><strong>Verbyo Business</strong></span></a>&nbsp;a comprehensive campaign management dashboard that empowers advertisers, foundations, and local authorities to create, launch, track, and analyze their campaigns with ease.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Introducing the&nbsp;<strong>PayPerPost</strong>&nbsp;system, Verbyo provides enhanced transparency and control for advertisers, as they pay for each post made on personal social media accounts. This system optimizes marketing strategies and ensures measurable campaign results.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Verbyo goes beyond being a marketing platform; it fosters a vibrant community committed to driving positive change worldwide.&nbsp;<strong>It empowers foundations to raise awareness for social causes through genuine voices on social media networks, offering this service free of charge</strong>.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>The people</strong>&nbsp;who share campaigns on their social media accounts are everyday individuals&mdash;friends, family members, or admired personalities. These individuals represent 82% of social media users, making them an ideal resource for advertising and message distribution.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>Verbyo offers numerous benefits to different stakeholders.</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Individuals</strong>&nbsp;can generate additional income and enhance their content quality.</p>
  
  <p><strong>Advertisers</strong>&nbsp;can boost sales, enhance brand visibility, and build customer trust.</p>
  
  <p><strong>Foundations</strong>&nbsp;can raise awareness, recruit volunteers, and execute impactful fundraising campaigns.</p>
  
  <p><strong>Local authorities</strong>&nbsp;can promote tourism, share messages of interest, and drive local development.</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p>&nbsp;</p>
  
  <p><strong>Verbyo&#39;s mission is to foster unity and empower individuals to enact positive change</strong>. The platform strictly prohibits the distribution of campaigns related to political, religious, violent, discriminatory, gambling, nudity, or misinformation-promoting content. By upholding these values, Verbyo cultivates a safe and inclusive environment that encourages constructive conversations and drives meaningful transformations.</p>
  
  `,
};
