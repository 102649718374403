import React from "react";
import Footer from "./reusable-ui/Footer";
import Navigation from "./reusable-ui/Navigation";

const Layout = ({ children }) => {
  return (
    <main className="min-h-screen relative bg-[#F8F8F8] w-full">
      <Navigation />
      <div className="max-w-[1286px] min-h-screen  lg:px-[50px]  mx-auto">
        <div className="h-full pb-[84px] ">{children}</div>
        <Footer />
      </div>
    </main>
  );
};

export default Layout;
